import React, { useState, useEffect } from "react"
import { withStoreContext } from "../../../../context/StoreContext"
import { injectIntl, Link } from "gatsby-plugin-intl"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"
import LoaderSpin from "../../../LoaderSpin"
import { withUserContext } from "../../../../context/UserContext"
import { MultipassURL } from "../../../../utils/multipass"
import { isBrowser } from "../../../../context/ApolloContext"
import {
  productTitle,
  linkCartProduct,
  returnCartImage,
} from "../../../../utils/cartHelpers"
import { removeFromCartScript } from "../../../../utils/additionalScriptHelpers"
import { getPrice } from "../../../../utils/navigateToCart"

const MiniCartPopOver = ({
  intl,
  storeContext,
  onMouseEnter,
  onMouseLeave,
  userContext,
  handleClose,
  exclusifPromo,
}) => {
  const {
    client,
    checkout,
    addVariantToCart,
    removeDiscount,
    removeLineItem,
    adding,
  } = storeContext
  const {
    profile: { email },
  } = userContext
  const [currData, setcurrData] = useState({})
  const [checkoutLoading, setcheckoutLoading] = useState(false)

  const data = useStaticQuery(graphql`
    query offerCardQueryAndMiniCartQuery {
      allDirectusCartTranslation {
        nodes {
          language
          checkout_text
          subtotal_text
          total_text
          your_cart_text
          empty_cart_text
          cart_text
          confirm_order_text
          two_product_text
        }
      }
      allShopifyProduct(sort: { order: ASC, fields: handle }) {
        edges {
          node {
            id: storefrontId
            createdAt
            updatedAt
            descriptionHtml
            description
            handle
            productType
            title
            vendor
            publishedAt
            options {
              id
              name
              values
            }
            variants {
              id: storefrontId
              title
              price
              weight
              sku
              image {
                id
                src: originalSrc
                #   altText
              }
              selectedOptions {
                name
                value
              }
            }
            images {
              src: originalSrc
              id
            }
            priceRangeV2 {
              minVariantPrice {
                amount
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }
  `)

  const setData = () => {
    let node
    if (data && data.allDirectusCartTranslation.nodes.length > 0) {
      data.allDirectusCartTranslation.nodes.forEach((item, index) => {
        if (intl.locale === item.language) {
          node = index
        }
      })
      setcurrData(data.allDirectusCartTranslation.nodes[node])
    }
  }

  const getTotalDiscount = () => {
    let total = 0
    checkout?.lineItems?.forEach(
      item =>
        (total =
          total +
          Number(
            (item.discountAllocations && item.discountAllocations.length) > 0
              ? item.discountAllocations[0].allocatedAmount.amount
              : 0
          ))
    )
    return getPrice(total)
  }

  const cartUrl = () => {
    let url = ""
    if (intl.locale === "fr") {
      url = "/panier/"
    } else {
      url = "/cart/"
    }
    return url
  }

  const checkoutUrl = async () => {
    setcheckoutLoading(true)
    // if (
    //   process.env.GATSBY_CURRENCY_TYPE === "eur" &&
    //   intl.locale === "fr" &&
    //   !exclusifPromo &&
    //   checkout.appliedGiftCards &&
    //   checkout?.appliedGiftCards?.length == 0
    // ) {
    //   let eGiftCardExist = false
    //   let eGiftCardIdArr = []

    //   checkout.lineItems.forEach(item => {
    //     if (item.title.includes("E-carte cadeau offerte")) {
    //       eGiftCardExist = true
    //       eGiftCardIdArr.push(item.id)
    //     }
    //   })
    //   if (eGiftCardExist && eGiftCardIdArr.length > 0) {
    //     eGiftCardIdArr.forEach(async item => {
    //       await removeLineItem(item)
    //     })
    //   }

    //   let GiftCardoptions = {}
    //   let eGiftCardPrice = 0
    //   let totalCheckoutPrice = checkout.subtotalPrice.amount

    //   if (totalCheckoutPrice >= 200) {
    //     if (totalCheckoutPrice >= 800) {
    //       GiftCardoptions.Montant = "200"
    //       eGiftCardPrice = 200
    //     } else if (totalCheckoutPrice >= 600) {
    //       GiftCardoptions.Montant = "150"
    //       eGiftCardPrice = 150
    //     } else if (totalCheckoutPrice >= 400) {
    //       GiftCardoptions.Montant = "100"
    //       eGiftCardPrice = 100
    //     } else if (totalCheckoutPrice >= 350) {
    //       GiftCardoptions.Montant = "75"
    //       eGiftCardPrice = 75
    //     } else if (totalCheckoutPrice >= 250) {
    //       GiftCardoptions.Montant = "50"
    //       eGiftCardPrice = 50
    //     } else {
    //       GiftCardoptions.Montant = "15"
    //       eGiftCardPrice = 15
    //     }
    //     GiftCardoptions.Montant = `${GiftCardoptions.Montant}€`

    //     let GiftCardProduct = data.allShopifyProduct.edges.find(
    //       item => item.node.handle === "e-gift-card"
    //     ).node

    //     const GiftCardVariant = client.product.helpers.variantForOptions(
    //       GiftCardProduct,
    //       GiftCardoptions
    //     )

    //     await addVariantToCart(GiftCardVariant.id, 1, null, checkout => {})
    //   }
    // }
    let url = ""
    url = email
      ? MultipassURL({
          email: email ? email : null,
          return_to: checkout?.webUrl + `&locale=${intl.locale}`,
        })
      : checkout?.webUrl + `&locale=${intl.locale}`

    window.location.href = url
  }

  useEffect(() => {
    setData()
  }, [intl.locale])

  return (
    isBrowser &&
    !window.location.pathname.includes("panier") &&
    !window.location.pathname.includes("cart") &&
    currData &&
    Object.keys(currData).length > 0 && (
      <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {/* <LoaderSpin loading={adding} /> */}
        <TitleWrapper>{currData.cart_text}</TitleWrapper>
        <Line />
        <Cross onClick={handleClose}>×</Cross>
        {checkout?.lineItems?.length > 0 ? (
          <>
            <Table>
              {checkout?.lineItems?.map((product, index) => (
                <TableRow key={index.toString()}>
                  <Link
                    to={linkCartProduct(
                      product.title,
                      product.variant,
                      intl.locale,
                      exclusifPromo
                    )}
                  >
                    <Image
                      className="lazyload"
                      data-src={
                        product.title === "gift-card"
                          ? returnCartImage(intl.locale)
                          : product.variant.image && product.variant.image.src
                      }
                    />
                  </Link>
                  <CartItemsTableRowCell width="50%">
                    <Link
                      to={linkCartProduct(
                        product.title,
                        product.variant,
                        intl.locale,
                        exclusifPromo
                      )}
                    >
                      <span
                        style={{
                          fontSize: 14,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>
                          {/* {product.title} {" - "} */}
                          {productTitle(
                            product.title,
                            product.variant,
                            intl.locale
                          )}
                        </span>
                        {/* {" "}
                        {product.variant.title} */}
                      </span>
                      <span style={{ fontSize: 18 }}>
                        <span style={{ fontSize: 11.5, fontWeight: 100 }}>
                          {product.quantity} x{" "}
                        </span>{" "}
                        {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                          ? process.env.GATSBY_CURRENCY
                          : ""}
                        {/* {Number(product.variant.price).toFixed(0)} */}
                        {getPrice(product.variant.price.amount)}
                        {process.env.GATSBY_CURRENCY_TYPE === "eur"
                          ? process.env.GATSBY_CURRENCY
                          : ""}
                      </span>
                    </Link>
                  </CartItemsTableRowCell>
                  <CrossDiv
                    onClick={() => {
                      removeLineItem(product.id)
                      removeFromCartScript(
                        product.variant.product.id,
                        product.title,
                        product.variant,
                        product.quantity,
                        intl.locale
                      )
                    }}
                  >
                    x
                  </CrossDiv>
                </TableRow>
              ))}
              <SubtitleRow>
                <span style={{ fontSize: 20 }}>
                  {currData.subtotal_text}: &nbsp;
                  <CurrencySymbol>
                    {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                      ? process.env.GATSBY_CURRENCY
                      : ""}
                  </CurrencySymbol>
                  {checkout?.lineItemsSubtotalPrice &&
                    getPrice(checkout?.lineItemsSubtotalPrice?.amount)}
                  <CurrencySymbol>
                    {process.env.GATSBY_CURRENCY_TYPE === "eur"
                      ? process.env.GATSBY_CURRENCY
                      : ""}
                  </CurrencySymbol>
                </span>
                {checkout?.discountApplications &&
                  checkout?.discountApplications.length > 0 &&
                  Number(checkout?.discountApplications[0].value.amount) !==
                    0 && (
                    <span
                      style={{
                        color: "#f05305",
                        fontSize: 12,
                        textAlign: "center",
                        lineHeight: 1,
                        fontFamily: "GothamLight , sans-serif!important",
                      }}
                    >
                      Code PROMO (
                      {(checkout?.discountApplications[0].title
                        ? checkout?.discountApplications[0].title
                        : checkout?.discountApplications[0].code
                        ? checkout?.discountApplications[0].code
                        : checkout?.discountApplications[0].description
                      ).toUpperCase()}
                      ): -
                      {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                        ? process.env.GATSBY_CURRENCY
                        : ""}
                      {/* {checkout?.discountApplications[0].value.amount} */}
                      {checkout?.discountApplications[0].value &&
                        getTotalDiscount()}
                      {process.env.GATSBY_CURRENCY_TYPE === "eur"
                        ? process.env.GATSBY_CURRENCY
                        : ""}
                    </span>
                  )}
              </SubtitleRow>
              <SubtitleRow>
                <span style={{ fontSize: 25 }}>
                  Total: &nbsp;
                  <CurrencySymbol>
                    {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                      ? process.env.GATSBY_CURRENCY
                      : ""}
                  </CurrencySymbol>
                  <strong>{getPrice(checkout?.subtotalPrice?.amount)}</strong>
                  <CurrencySymbol>
                    {process.env.GATSBY_CURRENCY_TYPE === "eur"
                      ? process.env.GATSBY_CURRENCY
                      : ""}
                  </CurrencySymbol>
                </span>
              </SubtitleRow>
              <CheckoutRow>
                <CheckoutButtonInternal
                  to={cartUrl()}
                  onClick={handleClose}
                  background={"#000000"}
                >
                  {currData.your_cart_text}
                </CheckoutButtonInternal>
                <CheckoutButton
                  onClick={() => checkoutUrl()}
                  checkoutLoading={checkoutLoading}
                >
                  {currData.checkout_text}
                </CheckoutButton>
              </CheckoutRow>
            </Table>
          </>
        ) : (
          <EmptyCartText>{currData.empty_cart_text}</EmptyCartText>
        )}
      </Wrapper>
    )
  )
}

const Wrapper = styled.div`
  width: 267px;
  background: #fff;
  padding: 20px 15px;
  font-family: "gothamLight";
  overflow-y: scroll;
  max-height: 100%;

  @media (min-width: 993px) {
    max-height: 450px;
  }
`

const CrossDiv = styled.div`
  display: flex;
  width: ${props => props.width || "auto"};
  justify-content: center;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 19px !important;
  border: 2px solid #ccc;
  color: #ccc;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  margin-left: 10px;
  cursor: pointer;
  font-family: "GothamLight", sans-serif !important;
  :hover {
    color: black;
    border: 2px solid black;
  }
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 0px;
`

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid rgb(236, 236, 236);
  box-sizing: border-box;
  padding: 0px 0px 10px 0px;
`

const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  align-self: center;
  margin: 0;
  margin-right: 10px;
`

const CurrencySymbol = styled.span`
  margin-top: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  font-family: "GothamLight", sans-serif !important;
`

const CartItemsTableRowCell = styled.div`
  display: flex;
  width: ${props => props.width || "auto"};
  align-items: center;
  font-family: "GothamLight", sans-serif !important;
  color: #000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > a {
    :hover {
      /* color: black; */
      font-weight: bold;
    }
  }
`

const SubtitleRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid rgb(236, 236, 236);
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  font-family: "GothamLight" !important;

  p {
    margin: 0px;
  }
`

const CheckoutRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  font-family: "GothamLight" !important;
`

const CheckoutButton = styled.button`
  background-color: ${({ background }) =>
    background ? background : "#008860"};
  width: 100%;
  margin: 0.5em 0 0;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.05);
  font-size: 14px;
  letter-spacing: 0.03em;
  cursor: pointer;
  line-height: 2.4em;
  border-radius: 0;
  padding: 0 10px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  position: relative;

  :hover {
    color: #fff;
  }

  :after {
    content: "";
    animation: productAddToCartLoader 1.3s infinite linear;
    border: 2px solid #fff;
    border-right-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    box-sizing: border-box;
    display: ${({ checkoutLoading }) => (checkoutLoading ? "block" : "none")};
    overflow: hidden;
    width: 18px;
    height: 18px;
    position: absolute;
    margin: 0px;
    top: 8px;
  }
`

const CheckoutButtonInternal = styled(Link)`
  background-color: ${({ background }) =>
    background ? background : "#000000"};
  width: 100%;
  margin: 0.5em 0 0;
  color: #fff;
  border-color: rgba(0, 0, 0, 0.05);
  font-size: 14px;
  letter-spacing: 0.03em;
  cursor: pointer;
  line-height: 2.4em;
  border-radius: 0;
  padding: 0 10px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  :hover {
    color: #fff;
  }
`

const EmptyCartText = styled.p`
  font-size: 14px;
  color: #777;
  font-weight: 400;
  margin: 0;
  padding-bottom: 0;
  margin: 15px 0px;
`

const TitleWrapper = styled.p`
  text-transform: uppercase;
  font-family: "GothamLight";
  font-size: 14px;
  line-height: 1.2;
  display: none;
  @media (max-width: 600px) {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
  }
`

const Line = styled.div`
  width: 20px;
  margin: auto;
  background-color: #000000;
  height: 3px;
  width: 30px;
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`

const Cross = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  font-family: Arial, monospace;
  font-weight: 300;
  color: #000;
  opacity: 0.5;
  display: none;
  border: none;
  background: transparent;
  font-size: 40px;
  text-transform: uppercase;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(0) translateZ(1px) scaleX(1);
  font-style: normal;
  outline: none;

  @media (max-width: 1200px) {
    display: inherit;
  }
  :hover {
    opacity: 1;
  }
`

export default injectIntl(withUserContext(withStoreContext(MiniCartPopOver)))
